import React from "react";
import { NavLink, NavMenu } from "./NavbarElements";

const Navbar = () => {
    return (
        <>
            <NavMenu>
                <NavLink to="/" activeStyle>
                    Home
                </NavLink>
                <NavLink to="/privacy-policy" activeStyle>
                    Privacy Policy
                </NavLink>
                <li><a href="mailto:necrodactyl@gmail.com?Subject=Hello%20again" target="_top">necrodactyl@gmail.com</a></li>
            </NavMenu>
        </>
    );
};

export default Navbar;