import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const NavLink = styled(Link)`
    color: #808080;
    display: flex;
    height: 36px;
    padding: 8px;
    margin-bottom: 7px;
    background-color :#3345e5;
    color: #fff;
    font-size: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    text-decoration: none;
    margin-top: 3px;
    text-align: center;
    display: block;
    color: #fff;
    &:hover {
        background-color: #0099cc;
    }
`;

export const NavMenu = styled.div`
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    max-width: 350px;
`;