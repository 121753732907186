import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages";
import PrivacyPolicy from './pages/privacy-policy';

function App() {
  return (
  <Router>
    <div class="wrapper">
      <div class="header">
          <img src="header.png" width="700" height="125" alt="logo" />
      </div>

      <div class="row">
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>

          <div class="col-4 menu">
            <p>
                Links:
            </p>
            <Navbar />
          </div>
      </div>

      
    </div>
    <div class="footer">
      <p>Faye Doherty 2024</p>
      </div>
  </Router>
  );
}

export default App;
