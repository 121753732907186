import React from "react";

const Home = () => {
    return (
        <div class="col-8 summary">
            <p>Hello there! Thanks for popping by to Necrodactyl.com, a home for dev experiments and fun apps!</p>
        </div>
    );
};

export default Home;